$offset: 187;
$duration: 1.4s;

.spinner {
    animation: rotator $duration linear infinite;
    vertical-align: middle;

    .path {
        stroke-dasharray: $offset;
        stroke-dashoffset: 0;
        transform-origin: center;
        animation:
            dash $duration ease-in-out infinite;
    }
}

@keyframes rotator {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(270deg);
    }
}

@keyframes dash {
    0% {
        stroke-dashoffset: $offset;
    }

    50% {
        stroke-dashoffset: calc($offset / 4);
        transform: rotate(135deg);
    }

    100% {
        stroke-dashoffset: $offset;
        transform: rotate(450deg);
    }
}

.centered-spinner {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}