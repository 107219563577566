
@import 'styles/_variables';
@import 'styles/_mixins';

.background-wrapper {
    display: flex;
    height: 100vh;
    width: 100vw;
    justify-content: center;
    align-items: center;
    background-color: $color-brand-dark;
    background-image: url(../../../assets/backgrounds/background-onboarding.svg);
    background-size: 100%;
    background-size: cover;
    &__content{
        padding: 20px;
        text-align: center;
        &-logo{
            margin: auto;
        }
    }
}
