@import 'styles/_variables';
@import 'styles/_mixins';

.button {
    position: relative;
    padding: 8px 16px !important;
    display: flex;
    align-items: center;
    min-height: 40px;

    &__spinner {
        position: absolute;
        left: auto;
        height: 16px;
        width: 16px;
        font-size: 14px;
    }
}

.btn {
    font-family: $font-family-Mulish-semi-bold;

    .content {
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        gap: 8px;
    }

    &-sm {
        padding-block: 7px !important;
        font-size: 12px !important;

        svg, img {
            height: 20px;
            width: 20px;
        }

        &.btn-link {
            font-size: 14px !important;
        }
    }

    &-lg {
        font-size: 14px !important;

        svg, img {
            height: 24px;
            width: 24px;
        }
    }

    &-primary {
        border-color: $davy-grey-500 !important;
        background-color: $davy-grey-500 !important;
        color: $neutral-0 !important;

        &:hover {
            border-color: $davy-grey-600 !important;
            background-color: $davy-grey-600 !important;
        }

        &:focus {
            box-shadow: none !important;
        }

        &:disabled {
            opacity: 1 !important;
            border-color: $davy-grey-100 !important;
            background-color: $davy-grey-100 !important;
            color: $davy-grey-400 !important;
        }
    }

    &-secondary {
        border-color: $stone-500 !important;
        background-color: $neutral-0 !important;
        color: $stone-500 !important;

        &:hover {
            border-color: $stone-600 !important;
            background-color: $stone-50 !important;
            color: $stone-600 !important;
        }

        &:focus {
            box-shadow: none !important;
            outline: 3px solid $stone-100 !important;
            border-color: $stone-100 !important;
        }

        &:disabled {
            opacity: 1 !important;
            border-color: $stone-200 !important;
            background-color: $neutral-0 !important;
            color: $stone-200 !important;
        }
    }

    &-danger {
        border-color: $color-danger !important;
        background-color: $color-danger !important;
        color: $neutral-0 !important;

        &:hover {
            border-color: darken($color: $color-danger, $amount: 5) !important;
            background-color: darken($color: $color-danger, $amount: 5) !important;
        }

        &:focus {
            box-shadow: none !important;
        }

        &:disabled {
            opacity: 1 !important;
            border-color: lighten($color: $color-danger, $amount: 10) !important;
            background-color: lighten($color: $color-danger, $amount: 10) !important;
            color: $neutral-0 !important;
        }
    }

    &-tertiary,
    &-link {
        height: auto !important;
        min-height: 0 !important;

        border-color: transparent;
        background-color: transparent;
        color: $stone-500 !important;

        &:hover {
            color: $stone-600 !important;
        }

        &:focus {
            box-shadow: none !important;
        }

        &:disabled {
            opacity: 1 !important;
            color: $stone-300 !important;
        }
    }

    &-link, &-link-light {
        padding: 0px !important;
        text-decoration: none !important;
    }

    &-icon-only {
        border-radius: 100% !important;

        border-color: $stone-500 !important;
        background-color: $neutral-0 !important;
        color: $stone-500 !important;
        min-height: 40px;
        min-width: 40px;
        padding: 0px 0px 0px 0px !important;

        svg {
            fill: $stone-500;
        }

        &:hover {
            background-color: $stone-50 !important;
        }

        &:focus {
            box-shadow: none !important;
            outline: 3px solid $stone-50 !important;
            border-color: $stone-50 !important;
        }

        &:active {
            background-color: $stone-50 !important;
        }

        &:disabled {
            opacity: 1 !important;
            background-color: $stone-50 !important;

            svg {
                fill: $stone-300 !important;
            }
        }
    }

    &-link-light {
        height: auto !important;
        min-height: 0 !important;

        border-color: transparent;
        background-color: transparent;
        color: $neutral-0 !important;

        &:hover {
            opacity: .6 !important;
        }

        &:focus {
            box-shadow: none !important;
        }

        &:disabled {
            opacity: 1 !important;
            color: $neutral-0 !important;
        }
    }

    &-base {
        border-color: $stone-500 !important;
        background-color: $stone-500 !important;
        color: $neutral-0 !important;

        &:hover {
            border-color: $stone-600 !important;
            background-color: $stone-600 !important;
        }

        &:focus {
            box-shadow: none !important;
        }

        &:disabled {
            opacity: 1 !important;
            border-color: $stone-100 !important;
            background-color: $stone-100 !important;
            color: $stone-400 !important;
        }
    }
}
