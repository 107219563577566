
@import 'styles/_variables';
@import 'styles/_mixins';

.flash-notification {
    position: fixed;
    top: 30px;
    right: 30px;
    transition: all 200ms ease-out;
    transform: translate(100%, 0);
    will-change: transform;
    opacity: 0;
    min-height: 44px;
    z-index: 1500;

    &.is-visible {
        transform: translate(0, 0);
        opacity: 1;
    }

    &__alert {
        box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
    }
}
