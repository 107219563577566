//-------------------------------------//
// MEDIA QUERIES

$media-lg: 1200px;
$media-md: 992px;
$media-sm: 768px;
$media-xs: 480px;


//-------------------------------------//
// DIMENSIONS

// navmenu height
$height-navmenu: 60px;

// sidebar
$sidebar-width: 250px;

// border-radius
$border-radius-base: 0;
$border-radius-small: 4px;
$border-radius-medium: 6px;
$border-radius-large: 9px;
$border-radius-xl: 10px;
$border-radius-xxl: 12px;

//-------------------------------------//
// COLORS

// color: background
$color-background: #fff;

// color: brand
$color-brand-green: #37A187;
$color-brand-dark: #464646;
$color-brand-light: #EFEFEF;

// color: font
$color-font-base: $color-brand-dark;

$color-primary: #0d6efd;
// $color-secondary: ;
$color-success: #545A4F;
$color-warning: #AFA47A;
$color-info: #698BB4;
$color-danger: #ED5B58;
// $color-light: ;

// colors: links
$color-link: #0d6efd;
$color-link-hover: lighten(#0d6efd, 15%);

// color: dividers/borders
$color-divider: #EFEFEF;
$color-border: $color-brand-dark;

// color: modal background
$color-modal-bg: #f5f7f9;

// color: shadow
$color-shadow: rgba(0, 0, 0, 0.11);

//-------------------------------------//
// SPACING

$spacing: 1.75rem;
$spacing-double: $spacing * 2;
$spacing-third: $spacing * 3;
$spacing-half: calc($spacing / 2);

$page-side-paddings: (
    xl: 70px,
    lg: 50px,
    md: 20px,
    sm: 5px,
    xs: 5px
);


//-------------------------------------//
// Z:INDEX

$zindex-header: 1000;
$zindex-dropdown: 1100;
$zindex-notify: 1300;
$zindex-modal: 1400;
$zindex-overlay: 100;

//-------------------------------------//
// FONTS

@font-face {
    font-family: "Mulish-regular";
    src: url('../assets/fonts/Mulish-regular.ttf') format("truetype");
}

@font-face {
    font-family: "Mulish-semiBold";
    src: url('../assets/fonts/Mulish-semiBold.ttf') format("truetype");
}

@font-face {
    font-family: "Mulish-bold";
    src: url('../assets/fonts/Mulish-bold.ttf') format("truetype");
}

@font-face {
    font-family: "Mulish-boldItalic";
    src: url('../assets/fonts/Mulish-boldItalic.ttf') format("truetype");
}

@font-face {
    font-family: "Mulish-italic";
    src: url('../assets/fonts/Mulish-italic.ttf') format("truetype");
}

@font-face {
    font-family: "Mulish-light";
    src: url('../assets/fonts/Mulish-light.ttf') format("truetype");
}

@font-face {
    font-family: "Mulish-lightItalic";
    src: url('../assets/fonts/Mulish-lightItalic.ttf') format("truetype");
}

@font-face {
    font-family: "Mulish-semiBoldItalic";
    src: url('../assets/fonts/Mulish-semiBoldItalic.ttf') format("truetype");
}

@font-face {
    font-family: "Mulish-extraLight";
    src: url('../assets/fonts/Mulish-extraLight.ttf') format("truetype");
}

@font-face {
    font-family: "Mulish-extraLightItalic";
    src: url('../assets/fonts/Mulish-extraLightItalic.ttf') format("truetype");
}

@font-face {
    font-family: "Mulish-black";
    src: url('../assets/fonts/Mulish-black.ttf') format("truetype");
}

@font-face {
    font-family: "Mulish-blackItalic";
    src: url('../assets/fonts/Mulish-blackItalic.ttf') format("truetype");
}

@font-face {
    font-family: "Jakarta-regular";
    src: url('../assets/fonts/PlusJakartaSans-Regular.ttf') format("truetype");
}

@font-face {
    font-family: "Jakarta-semiBold";
    src: url('../assets/fonts/PlusJakartaSans-SemiBold.ttf') format("truetype");
}

@font-face {
    font-family: "Jakarta-bold";
    src: url('../assets/fonts/PlusJakartaSans-Bold.ttf') format("truetype");
}

@font-face {
    font-family: "Jakarta-boldItalic";
    src: url('../assets/fonts/PlusJakartaSans-BoldItalic.ttf') format("truetype");
}

@font-face {
    font-family: "Jakarta-italic";
    src: url('../assets/fonts/PlusJakartaSans-Italic.ttf') format("truetype");
}

@font-face {
    font-family: "Jakarta-light";
    src: url('../assets/fonts/PlusJakartaSans-Light.ttf') format("truetype");
}

@font-face {
    font-family: "Jakarta-lightItalic";
    src: url('../assets/fonts/PlusJakartaSans-LightItalic.ttf') format("truetype");
}

@font-face {
    font-family: "Jakarta-semiBoldItalic";
    src: url('../assets/fonts/PlusJakartaSans-SemiBoldItalic.ttf') format("truetype");
}

@font-face {
    font-family: "Jakarta-extraLight";
    src: url('../assets/fonts/PlusJakartaSans-ExtraLight.ttf') format("truetype");
}

@font-face {
    font-family: "Jakarta-extraLightItalic";
    src: url('../assets/fonts/PlusJakartaSans-ExtraLightItalic.ttf') format("truetype");
}

@font-face {
    font-family: "Jakarta-black";
    src: url('../assets/fonts/PlusJakartaSans-ExtraBold.ttf') format("truetype");
}

@font-face {
    font-family: "Jakarta-blackItalic";
    src: url('../assets/fonts/PlusJakartaSans-ExtraBoldItalic.ttf') format("truetype");
}


@font-face {
    font-family: "NotoSerif-regular";
    src: url('../assets/fonts/NotoSerif-Regular.ttf') format("truetype");
}

@font-face {
    font-family: "NotoSerif-semiBold";
    src: url('../assets/fonts/NotoSerif-SemiBold.ttf') format("truetype");
}

@font-face {
    font-family: "NotoSerif-bold";
    src: url('../assets/fonts/NotoSerif-Bold.ttf') format("truetype");
}

@font-face {
    font-family: "NotoSerif-boldItalic";
    src: url('../assets/fonts/NotoSerif-BoldItalic.ttf') format("truetype");
}

@font-face {
    font-family: "NotoSerif-italic";
    src: url('../assets/fonts/NotoSerif-Italic.ttf') format("truetype");
}

@font-face {
    font-family: "NotoSerif-light";
    src: url('../assets/fonts/NotoSerif-Light.ttf') format("truetype");
}

@font-face {
    font-family: "NotoSerif-lightItalic";
    src: url('../assets/fonts/NotoSerif-LightItalic.ttf') format("truetype");
}

@font-face {
    font-family: "NotoSerif-semiBoldItalic";
    src: url('../assets/fonts/NotoSerif-SemiBoldItalic.ttf') format("truetype");
}

@font-face {
    font-family: "NotoSerif-extraLight";
    src: url('../assets/fonts/NotoSerif-ExtraLight.ttf') format("truetype");
}

@font-face {
    font-family: "NotoSerif-extraLightItalic";
    src: url('../assets/fonts/NotoSerif-ExtraLightItalic.ttf') format("truetype");
}

@font-face {
    font-family: "NotoSerif-black";
    src: url('../assets/fonts/NotoSerif-ExtraBold.ttf') format("truetype");
}

@font-face {
    font-family: "NotoSerif-blackItalic";
    src: url('../assets/fonts/NotoSerif-ExtraBoldItalic.ttf') format("truetype");
}


@font-face {
    font-family: "SyracuseBlockCondensed-regular";
    src: url('../assets/fonts/SyracuseBlockCondensed-regular.ttf') format("truetype");
}

@font-face {
    font-family: "SyracuseBlockCondensed-semiBold";
    src: url('../assets/fonts/SyracuseBlockCondensed-semiBold.ttf') format("truetype");
}

@font-face {
    font-family: "SyracuseBlockCondensed-bold";
    src: url('../assets/fonts/SyracuseBlockCondensed-bold.ttf') format("truetype");
}

@font-face {
    font-family: "SyracuseBlockCondensed-boldItalic";
    src: url('../assets/fonts/SyracuseBlockCondensed-boldItalic.ttf') format("truetype");
}

@font-face {
    font-family: "SyracuseBlockCondensed-light";
    src: url('../assets/fonts/SyracuseBlockCondensed-light.ttf') format("truetype");
}

@font-face {
    font-family: "SyracuseBlockCondensed-lightItalic";
    src: url('../assets/fonts/SyracuseBlockCondensed-lightItalic.ttf') format("truetype");
}

@font-face {
    font-family: "SyracuseBlockCondensed-semiBoldItalic";
    src: url('../assets/fonts/SyracuseBlockCondensed-semiBoldItalic.ttf') format("truetype");
}

@font-face {
    font-family: "SyracuseBlockCondensed-extraLight";
    src: url('../assets/fonts/SyracuseBlockCondensed-extraLight.ttf') format("truetype");
}

@font-face {
    font-family: "SyracuseBlockCondensed-extraLightItalic";
    src: url('../assets/fonts/SyracuseBlockCondensed-extraLightItalic.ttf') format("truetype");
}

@font-face {
    font-family: "SyracuseBlockCondensed-black";
    src: url('../assets/fonts/SyracuseBlockCondensed-extraBold.ttf') format("truetype");
}

@font-face {
    font-family: "SyracuseBlockCondensed-blackItalic";
    src: url('../assets/fonts/SyracuseBlockCondensed-extraBoldItalic.ttf') format("truetype");
}

// fonts: base styles
$font-family-Mulish-regular: 'Mulish-regular';
$font-family-Mulish-italic: 'Mulish-italic';
$font-family-Mulish-light: 'Mulish-light';
$font-family-Mulish-light-italic: 'Mulish-lightItalic';
$font-family-Mulish-extra-light: 'Mulish-extraLight';
$font-family-Mulish-extra-light-italic: 'Mulish-extraLightItalic';
$font-family-Mulish-bold: 'Mulish-bold';
$font-family-Mulish-bold-italic: 'Mulish-boldItalic';
$font-family-Mulish-semi-bold: 'Mulish-semiBold';
$font-family-Mulish-semi-bold-italic: 'Mulish-semiBoldItalic';
$font-family-Mulish-black: 'Mulish-black';
$font-family-Mulish-black-italic: 'Mulish-blackItalic';

$font-family-Jakarta-regular: 'Jakarta-regular';
$font-family-Jakarta-italic: 'Jakarta-italic';
$font-family-Jakarta-light: 'Jakarta-light';
$font-family-Jakarta-light-italic: 'Jakarta-lightItalic';
$font-family-Jakarta-extra-light: 'Jakarta-extraLight';
$font-family-Jakarta-extra-light-italic: 'Jakarta-extraLightItalic';
$font-family-Jakarta-bold: 'Jakarta-bold';
$font-family-Jakarta-bold-italic: 'Jakarta-boldItalic';
$font-family-Jakarta-semi-bold: 'Jakarta-semiBold';
$font-family-Jakarta-semi-bold-italic: 'Jakarta-semiBoldItalic';
$font-family-Jakarta-black: 'Jakarta-black';
$font-family-Jakarta-black-italic: 'Jakarta-blackItalic';

$font-family-NotoSerif-regular: 'NotoSerif-regular';
$font-family-NotoSerif-italic: 'NotoSerif-italic';
$font-family-NotoSerif-light: 'NotoSerif-light';
$font-family-NotoSerif-light-italic: 'NotoSerif-lightItalic';
$font-family-NotoSerif-extra-light: 'NotoSerif-extraLight';
$font-family-NotoSerif-extra-light-italic: 'NotoSerif-extraLightItalic';
$font-family-NotoSerif-bold: 'NotoSerif-bold';
$font-family-NotoSerif-bold-italic: 'NotoSerif-boldItalic';
$font-family-NotoSerif-semi-bold: 'NotoSerif-semiBold';
$font-family-NotoSerif-semi-bold-italic: 'NotoSerif-semiBoldItalic';
$font-family-NotoSerif-black: 'NotoSerif-black';
$font-family-NotoSerif-black-italic: 'NotoSerif-blackItalic';

$font-family-SyracuseBlockCondensed-regular: 'SyracuseBlockCondensed-regular';
$font-family-SyracuseBlockCondensed-light: 'SyracuseBlockCondensed-light';
$font-family-SyracuseBlockCondensed-light-italic: 'SyracuseBlockCondensed-lightItalic';
$font-family-SyracuseBlockCondensed-extra-light: 'SyracuseBlockCondensed-extraLight';
$font-family-SyracuseBlockCondensed-extra-light-italic: 'SyracuseBlockCondensed-extraLightItalic';
$font-family-SyracuseBlockCondensed-bold: 'SyracuseBlockCondensed-bold';
$font-family-SyracuseBlockCondensed-bold-italic: 'SyracuseBlockCondensed-boldItalic';
$font-family-SyracuseBlockCondensed-semi-bold: 'SyracuseBlockCondensed-semiBold';
$font-family-SyracuseBlockCondensed-semi-bold-italic: 'SyracuseBlockCondensed-semiBoldItalic';
$font-family-SyracuseBlockCondensed-black: 'SyracuseBlockCondensed-black';
$font-family-SyracuseBlockCondensed-black-italic: 'SyracuseBlockCondensed-blackItalic';


$font-size-base: 1rem;

// fonts: weights
$font-light: 300;
$font-normal: 400;
$font-medium: 600;
$font-bold: 700;

$davy-grey-50: #F2F3F1;
$davy-grey-100: #E5E7E4;
$davy-grey-200: #CCCFC9;
$davy-grey-300: #B2B7AE;
$davy-grey-400: #98A092;
$davy-grey-500: #545A4F;
$davy-grey-600: #4C5148;
$davy-grey-700: #3D413A;
$davy-grey-800: #333630;
$davy-grey-900: #191B18;

$stone-50: #F5F4EF;
$stone-100: #ECE9DF;
$stone-200: #D9D4BF;
$stone-300: #C6BE9F;
$stone-400: #B7AD86;
$stone-500: #AFA47A;
$stone-600: #7F754D;
$stone-700: #605839;
$stone-800: #403B26;
$stone-900: #201D13;

$neutral-0: #FFF;
$neutral-50: #F2F2F2;
$neutral-100: #D5D5D5;
$neutral-200: #B3B3B3;
$neutral-300: #818181;
$neutral-400: #575757;
$neutral-500: #292929;
$neutral-600: #222222;
$neutral-700: #1F1F1F;
$neutral-800: #121212;
$neutral-900: #000000;
$neutral-1000: #CCCCCC;

$depressed: #ED5B58;
$depressed-bg: #FFDBD6;

$sad: #FF9C00;
$sad-bg: #FFEDDC;

$fine: #FBBA18;
$fine-bg: #FFEEC0;

$happy: #56D198;
$happy-bg: #DEFFF0;

$ecstatic: #54C9EA;
$ecstatic-bg: #E7FAFF;

$drop-shadow-2: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.1));
$drop-shadow-3: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.1));
$drop-shadow-4: drop-shadow(0px 6px 12px rgba(0, 0, 0, 0.1));
$drop-shadow-5: drop-shadow(0px 8px 16px rgba(0, 0, 0, 0.1));
$drop-shadow-6: drop-shadow(0px 10px 24px rgba(0, 0, 0, 0.1));

$red-50: #f8e2e2;
$red-100: #f2b3b3;
$red-200: #eb8080;
$red-300: #e34c4c;
$red-400: #db2323;
$red-500: #c71414; // Base color
$red-600: #ae1212;
$red-700: #961010;
$red-800: #7e0d0d;
$red-900: #670b0b;
$red-1000: #4f0808;

$blue-50: #e2e8f2;
$blue-100: #b3c2db;
$blue-200: #809ac3;
$blue-300: #4c70ab;
$blue-400: #234d95;
$blue-500: #002254; // Base color
$blue-600: #001e4b;
$blue-700: #001841;
$blue-800: #001337;
$blue-900: #000d2d;
$blue-1000: #000824;

$rowing-blue-50: #e2e7f2;
$rowing-blue-100: #b3c1db;
$rowing-blue-200: #809ac3;
$rowing-blue-300: #4c72ab;
$rowing-blue-400: #234f94;
$rowing-blue-500: #002147; // Base color
$rowing-blue-600: #001d3f;
$rowing-blue-700: #001937;
$rowing-blue-800: #00152f;
$rowing-blue-900: #001027;
$rowing-blue-1000: #000c1f;

$syracuse-orange-50: #fde8d9;
$syracuse-orange-100: #fbc4a6;
$syracuse-orange-200: #f99f73;
$syracuse-orange-300: #f77a40;
$syracuse-orange-400: #f55d19;
$syracuse-orange-500: #F46A00; // Base color
$syracuse-orange-600: #d15e00;
$syracuse-orange-700: #ae5200;
$syracuse-orange-800: #8b4600;
$syracuse-orange-900: #693900;
$syracuse-orange-1000: #462d00;
