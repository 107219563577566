@import 'styles/_variables';
@import 'styles/_mixins';

.avatar {
    overflow: hidden;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &__wrapper {}

    &-circle {
        border-radius: 100% !important;
    }

    &-14 {
        height: 14px;
        width: 14px;
        min-width: 14px;
        min-height: 14px;
    }

    &-24 {
        height: 24px;
        width: 24px;
        min-width: 24px;
        min-height: 24px;
    }

    &-25 {
        height: 25px;
        width: 25px;
        min-width: 25px;
        min-height: 25px;
    }

    &-28 {
        height: 28px;
        width: 28px;
        min-width: 28px;
        min-height: 28px;
    }

    &-30 {
        height: 30px;
        width: 30px;
        min-width: 28px;
        min-height: 28px;
    }

    &-32 {
        height: 32px;
        width: 32px;
        min-width: 28px;
        min-height: 28px;
    }

    &-40 {
        height: 40px;
        width: 40px;
        min-width: 40px;
        min-height: 40px;
    }

    &-47 {
        height: 47px;
        width: 47px;
        min-width: 47px;
        min-height: 47px;
    }

    &-60 {
        height: 60px;
        width: 60px;
        min-width: 60px;
        min-height: 60px;
    }

    &-72 {
        height: 72px;
        width: 72px;
        min-width: 72px;
        min-height: 72px;
    }

    &-80 {
        height: 80px;
        width: 80px;
        min-width: 80px;
        min-height: 80px;
    }

    &-90 {
        height: 90px;
        width: 90px;
        min-width: 90px;
        min-height: 90px;
    }

    &-120 {
        height: 120px;
        width: 120px;
        min-width: 120px;
        min-height: 120px;
    }

    &-136 {
        height: 136px;
        width: 136px;
        min-width: 136px;
        min-height: 136px;
    }

    &-190 {
        height: 190px;
        width: 190px;
        min-width: 190px;
        min-height: 190px;
    }
}
