@import '_variables';

$theme-colors: (
    'primary': $color-primary,
    'secondary': $neutral-400,
    'success': $color-brand-green,
    'danger': $color-danger,
    'warning': $color-warning,
    'info':$color-info,
    'light': $neutral-50,
    'dark': $neutral-500
);

@import '~bootstrap/scss/bootstrap';