@import 'styles/_variables';
@import 'styles/_mixins';

.modal {
    &__header {
        display: flex;
        justify-content: space-between;
        align-self: center;
        width: 100%;
        margin-bottom: 40px;

        @include screen-sm {
            margin-bottom: 20px;
        }
    }

    &-body {
        width: 100%;
    }

    &-content {
        text-align: left;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 60px;
        gap: 40px;

        background: $neutral-0;
        border-radius: $border-radius-small;

        filter: $drop-shadow-5;

        @include screen-lg {
            // min-width: 544px !important;
        }

        @include screen-sm {
            padding: 30px;
        }
    }

    &-button-wrapper {
        display: flex;
    }

    .modal-dialog {
        padding: 0 !important;
        min-width: 544px;

        @include screen-md {
            max-width: calc(100% - calc(132px * 2)) !important;
            padding-inline: 24px;
            min-width: 0;
            margin-inline: auto;
        }

        @include screen-xs {
            max-width: calc(100% - calc(24px * 2)) !important;
            padding-inline: 24px;
            min-width: 0;
            margin-inline: auto;
        }

        .modal-body {
            padding: 0 !important;
        }

    }
}
