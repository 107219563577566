
@import 'styles/_variables';
@import 'styles/_mixins';

.page-error {
    display: flex;
    justify-content: center;
    align-items: center;
    &__wrapper{
        text-align: center;
        button{
        }
        &-title{
            margin: 20px 0;
        }
    }
}
