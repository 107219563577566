
@import 'styles/_variables';
@import 'styles/_mixins';

.modal-confirm {
    display: flex;
    flex-direction: column;
    gap: 40px;

    &__button-container {
        width: 100%;

        display: flex;
        flex-direction: row;
        gap: 12px;

        .btn {
            flex: 1
        }
    }
}
